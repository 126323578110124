.Zoom {
    display: flex;
    align-items: center;
    user-select: none;

    &-button {
        background-color: #354530;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &-slider {
        border: 1px solid #D2D2D2;
        box-sizing: border-box;
        padding: 0 8px;
        width: 142px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
    }
}