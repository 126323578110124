.SideSectionHeader {
    background-color: white;
    padding: 16px;

    &-primary-text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 24px;
    }

    &-secondary-text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #A5ABB3;
    }
}