.text-icon {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: all .7s;

    display: flex;
    align-items: center;

    color: #09101D;
}