* {
  font-family: Source Sans Pro;
  box-sizing: border-box;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.CPP-left-shadow {
  -webkit-box-shadow: inset 8px 0px 9px 0px rgba(43,43,43,0.03);
  -moz-box-shadow: inset 8px 0px 9px 0px rgba(43,43,43,0.03);
  box-shadow: inset 8px 0px 9px 0px rgba(43,43,43,0.05);
}

.CPP-left-shadow-dark {
  -webkit-box-shadow: inset 8px 0px 9px 0px rgba(43,43,43,0.17);
  -moz-box-shadow: inset 8px 0px 9px 0px rgba(43,43,43,0.17);
  box-shadow: inset 8px 0px 9px 0px rgba(43,43,43,0.17);
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent; 
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media (max-width: 1200px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

.fill-screen {
  /* min-height: -webkit-fill-available; */
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 80px);
}

input[type="time"]::-webkit-inner-spin-button {
display: none;
}

input[type="time"]::-webkit-outside-spin-button {
    display: none;
}input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0 !important; 

}

.MuiOutlinedInput-inputMarginDense {
    width: 27px !important;
}