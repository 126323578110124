.default-button {
    padding: 0px;
    width: 190px;
    height: 36px;
    left: 1180px;
    top: 112px;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    border-radius: 8px;
    border-color: #354530;
    color: #354530;
    background-color: white;
    cursor: pointer;
}