.model-table {
  // height: 0vh;
  border: 0;
  color: red;
  & .MuiDataGrid-columnsContainer {
    background: #f4f6f9;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 24px;
    color: #2c3a4b;
  }

  & .MuiDataGrid-iconSeparator {
    display: none;
  }
  & .num-id {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #09101d;
    flex-grow: 0;
  }

  & .header {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    color: #09101d;
  }

  & .MuiSvgIcon-root {
    width: 17px;
    height: 17px;
  }

  img {
    margin-top: 7%;
    width: 160px;
    height: 160px;

    border: 1px solid #dadee3;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .status {
    height: 28px;
    width: 58px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 8px;
    line-height: 0px;
    border-radius: 4px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}
