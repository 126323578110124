.UserCustomizeText {

    &-hover {
        position: relative;
        overflow-x: hidden;
        transition: all .3s;

        &:hover {
            & .UserCustomizeText-remove-icon {
                right: 0;
            }
        }
    }

    &-remove-icon {
        transition: all .3s;
        position: absolute;
        top: 10px;
        right: -50px;
        cursor: pointer;
    }
}