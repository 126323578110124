.EditPages {

    &-image { 
        width: 250px; 
        height: 250px;
        border: 1px solid grey;
        background-size: contain;
        background-position: center;
        margin-bottom: 16px;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}