.model {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    margin-bottom: 14px;
    width: 310px;
    height: 155px !important;

    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.22);
    border-radius: 8px;

    .left {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 70%;
        }
    }
    .right {
        p {
            font-size: 14px;
            margin-bottom: 4px;
        }
        h3 {
            font-size: 19px;
            color: black;
        }
        position: static;
        
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #858C94;
    }
}