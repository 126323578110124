.customize-container {
    -webkit-appearance:none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    button {
        color: #858C94;
    }
    select, option {
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
    }

    .custom-select {
        border-radius: 5px;
        padding: 8.5px 14px;
        font-size: 18px;
        background-color: white;
    }
    .custom-option {
        font-size: 18px;
        padding: 8px 16px;
    }
    .MuiOutlinedInput-input {
        width: 160px !important;
        padding: 8.5px 14px;
    }
    #canvas-container {
        position: relative;
        display: flex;
        justify-content: center;
    }

    #delete-button {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
    }
}

.grab-cursor * {
    cursor: grab !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
    padding-top: 6px;
    padding-bottom: 6px;
}

.MuiButton-outlinedSecondary {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

// .MuiToolbar-regular {
//     height: 43px !important;
//     min-height: 43px !important;
// }

#menu- > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    transform: translateY(77px) !important;
}