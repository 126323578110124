.UCI {

    &-upload-button {
        border: 1px dashed #777;
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        user-select: none;
    }

    &-images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-top: 10px;
    }

    &-image-container {
        height: 122px;
        border-radius: 3px;
        background-color: rgb(217, 217, 217);
        overflow: hidden;
    }

    &-image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
    }

    &-remove-button {
        cursor: pointer;
        // opacity: 0;
        transition: all .3s !important;
    }

    &-image:hover &-remove-button {
        opacity: 1;
    }
}