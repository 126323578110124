.mid-container {
    // box-shadow: 0px 1.25px 4px rgba(0, 0, 0, 0.1);
    .mid-header-container {
        padding: 20px;

        height: 88px;
        p {
            padding-top: 20px;
            color: #A5ABB3;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;   
        }
        h3 {
            margin-top: -20px !important;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 23px;
            line-height: 24px;
    
            display: flex;
            align-items: center;
            color: #000000;
        }
    }    
    .mid-mid-container {
        padding: 20px;
        min-height: 100%;
        background: #F7FCF8;
    }
}


.right-container {
    box-shadow: 0px 1.25px 4px rgba(0, 0, 0, 0.1);
    width: 160px;
    .right-header-container {
        top: calc(50% - 0px/2);        
        button {
            width: 50%;
            height: 40px;
            background: #FFFFFF;
            border: none;
            // box-shadow: -1.25px 0px 4px rgba(0, 0, 0, 0.15);
        }
    }
    .choices {
        margin-top: 20px;
        padding-left: 20px;
    }
}