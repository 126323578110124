.model-top-bar {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    
    p {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 23px;
        line-height: 24px;   
    }

    .left-side{
        display: flex;
    }
}

.default-search-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    width: 214px;
    height: 36px;
    background: #F4F6F9;
    border-radius: 8px;

    input {
        border: none;
        background-color: transparent;
        &:focus, :active{
            border: none;
            outline: none;
        }
        &::placeholder{
            color: #09101D;
        }
    }
}