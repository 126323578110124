.editor {
    display: flex;
    flex-direction: column;
    p {
        margin-bottom: 2px;
    }
    display: flex;

    input {
        padding: 12px 16px;
        height: 48px;

        background: #FFFFFF;
        border: 1px solid #858C94;
        box-sizing: border-box;
        border-radius: 8px;

        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        color: #09101D;
        &::placeholder {
            color: #09101D;
            opacity: 0.5;
        }
    }
    .select {
        flex-direction: row;
        justify-content: center;
        padding: 20px 16px;

        width: 310px;
        height: 48px;

        background: #FFFFFF;
        opacity: 0.5;

        border: 1px solid #858C94;
        box-sizing: border-box;
        border-radius: 8px;
        margin: 8px 0px;
    }
}