#fixed {
    position: fixed;
}
#fixed.absolute {
    position: absolute;
}

.UserCustomizeGeneral {
    padding-top: 16px;

    &-label {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #09101D;
        margin-bottom: 8px;
    }

    &-select {
        width: 100%;
        padding: 12px 28px 12px 16px;
        color: rgba(9, 16, 29, .6);
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        border-radius: 8px;
        border: 1px solid #858C94;
        outline: none;
        -webkit-appearance: none;
        position: relative;
    }

    &-quantity {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        background: #FFFFFF;
        border: 1px solid #858C94;
        box-sizing: border-box;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;

        &-number {
            font-size: 18px;
            width: 24px;
            text-align: center;
            padding: 12px 0;
        }

        &-sign {
            color: #858C94;
            font-size: 32px;
            line-height: 24px;
            padding: 12px 16px;
        }
    }
}