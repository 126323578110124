.EditProduct {

    &-material-round-image {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-position: center;
        border: 2px solid white;
    }

    &-model-images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }

    &-model-single-image {
        width: 100%;
        // margin: 8px;
    }
}