.CPP {

    &-mobile-side-open {
        right: 0 !important;
    }
}

@media (max-height: 500px) {
    .CPP-android-keyboard-expand {
        // height: calc(150% - 65px - 80px) !important;
    }

    // .CPP-android-keyboard-text {
    //     min-height: 200px;
    // }
}