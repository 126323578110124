.ATCD {
    max-width: 350px;
    padding: 24px;

    &-top {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &-center-child {
        display: flex;
        justify-content: center;
    }

    &-success-text {
        text-align: center;
        font-size: 20px;
        font-family: Source Sans Pro;
        margin: 32px 0;
    }

    &-add-to-cart-button {
        background: #354530;
        border-radius: 2px;
        padding: 12px 48px;
        cursor: pointer;
        color: white;
        transition: all .47s;

        &:hover {
            opacity: .8;
        }
    }
}