.general-container {
  .title {
    position: static;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;

    color: #09101d;

    opacity: 0.8;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 4px 0px;
  }

  .description {
    height: 24px;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: #09101d;
    opacity: 0.6;
  }
  .price {
    height: 32px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
    
    color: #09101D;
  }
}
