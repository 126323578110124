.MaterialSelection {

    &-title {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    &-images-container {
        display: flex;
        margin: 16px 0;
    }

    &-material-image {
        width: 60px;
        height: 60px;
        border: 2px solid rgba(248, 186, 68, 1);
        background-position: center;
        border-radius: 50%;
        margin-right: 12px;
        cursor: pointer;

        &-selected {
            border-width: 4px;
            border-color: #F8BA44;
        }
    }

    &-name {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #09101D;
        opacity: .6;
        margin: 12px 0 20px;
    }
}