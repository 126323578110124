.insert-container {
    display: flex;
    margin-top: 32px;
    p {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 2px;
        color: #09101D;
        opacity: 0.8;
    }

    .editor {
        // margin-left: 20px;
        
        display: flex;
        flex-direction: column;

        display: flex;

        input {
            padding: 12px 16px;

            bottom: 0px;
            width: 310px;
            height: 48px;
            left: 0px;
            top: 32px;

            background: #FFFFFF;
            border: 1px solid #858C94;
            box-sizing: border-box;
            border-radius: 8px;

            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            color: #09101D;
            &::placeholder {
                color: #09101D;
                opacity: 0.5;
            }
        }
        .select {
            flex-direction: row;
            justify-content: center;
            padding: 20px 16px;
    
            width: 310px;
            height: 48px;
    
            background: #FFFFFF;
            opacity: 0.5;
    
            border: 1px solid #858C94;
            box-sizing: border-box;
            border-radius: 8px;
            margin: 8px 0px;
        }
    }
}

.insert-image {
    height: 420px;
    width: 420px;
    top: 188px;
    left: 290px;
    background: #FFFFFF;

    border: 1px solid #DADEE3;
    box-sizing: border-box;
    border-radius: 4px;
}


.input-button {
    input {
        display: none;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 12px;

    position: absolute;
    width: 224px;
    height: 36px;
    left: 388px;
    top: 624px;

    border: 1px solid #354530;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 14px;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #354530;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
}