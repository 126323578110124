.EditModel {
    width: 100%;

    &-image {
        width: 120px;
        height: 120px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid #DADEE3;
        margin-bottom: 16px;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-preview-image {
        width: 100%;
        padding-top: 72.1%;
        margin-bottom: 16px;
        margin-top: 16px;
        border: 1px solid #DADEE3;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }

    &-1st-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 16px;
    }
}