.ModelsSelection {

    &-label {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
    }
    
    &-model-container {
        display: grid;
        grid-template-columns: 8fr 5fr;
        column-gap: 16px;
        margin-bottom: 16px;
        border-radius: 8px;
        background-color: white;
        overflow: hidden;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.22);
        cursor: pointer;
    }

    &-model {

        &-image {
            height: 150px;
            background-position: center;
            background-size: contain;
            background-color: #F8F8F8;
            background-repeat: no-repeat;
        }

        &-preview-image {
            background-position: center;
            background-size: contain;
            background-color: #F8F8F8;
            background-repeat: no-repeat;
            padding-top: 81.1%;
        }

        &-name {
            font-weight: 600;
            font-size: 20px;
            color: #858C94;
            font-family: Source Sans Pro;
            height: 100%;
            width: 80%;
            display: flex;
            justify-self: center;
            align-items: center;
            text-align: center;
        }
    }
}