.UCATC {
    width: 100%;

    &-info {
        background: #FFFFFF;
        box-shadow: inset 8px 0px 9px 0px rgba(43,43,43,0.05);
        // box-shadow: 0px -1.25px 4px rgba(0, 0, 0, 0.1);
        padding: 16px;
        width: 100%;
    }

    &-info-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 4px;
        
        &-name {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #919191;
        }

        &-value {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
            color: #303030;
        }
    }

    &-add-to-cart-button {
        position: relative;
        height: 72px;
        // background-color: #354530;
        box-shadow: 0px -1.25px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: white;
        cursor: pointer;
        transform: all .7s;
        overflow: hidden;

        // &:hover {
        //     opacity: .8;
        // }
    }

    &-add-to-cart-sub-button {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        transition: all .7s;
        background-color: #354530;
        color: white;

        &-hide {
            flex: 0;
            margin-left: -80px;
            color: transparent;
        }

        &:hover {
            opacity: .8;
        }
    }
    
}