.editor {
    // margin-left: 20px;
    display: flex;
    flex-direction: column;
    p {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 2px;
        color: #09101D;
        opacity: 0.8;
    }

    .font-name {
        padding: 12px 16px;

        bottom: 0px;
        width: 310px;
        height: 48px;
        left: 0px;
        top: 32px;

        background: #ffffff;
        border: 1px solid #858c94;
        box-sizing: border-box;
        border-radius: 8px;

        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        color: #09101d;
        &::placeholder {
            color: #09101d;
            opacity: 0.5;
        }
    }
    .fontupload-button {
        input {
            display: none;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 12px;
        margin-top: 10px;
    
        width: 310px;
        height: 36px;
    
        border: 1px solid #354530;
        box-sizing: border-box;
        border-radius: 6px;
        font-size: 14px;
    
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
    
        color: #354530;
    }    
}
